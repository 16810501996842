import {styled} from "@mui/material";

export const Box = styled(`div`)(({theme}) => ({
  padding: "80px 60px",
  backgroundColor: "gray",
  position: "fixed",
  bottom: 0,
  width: "100%",
  [theme.breakpoints.up("md")]: {
    padding: "70px 30px",
  },
}));

export const Container = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "1000px",
  margin: "0 auto",
  /* background: red; */
}));

export const Column = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  marginLeft: "60px",
}));

export const Row = styled(`div`)(({theme}) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(185px, 1fr))",
  gridGap: "20px",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  },
}));

export const FooterLink = styled(`a`)(() => ({
  color: "#fff !important",
  marginBottom: "20px",
  fontSize: "18px",
  textDecoration: "none",
  "&:hover": {
    color: "green !important",
    transition: "200ms ease-in",
  },
}));

export const Heading = styled(`p`)(() => ({
  fontSize: "24px",
  color: "#fff",
  marginBottom: "40px",
  fontWeight: "bold",
}));
