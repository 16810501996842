import {
  doc,
  serverTimestamp,
  setDoc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import {db} from "../../firebase";
const collection_name = "coupons";

export default class CouponsAPI {
  // Add/Update Coupon
  async setCoupon(data, updateTime = true) {
    // console.log(
    //   "API CALL =========> set Coupon--add form data:",
    //   data.payload,
    //   " to id:",
    //   data.id
    // );
    const payloadData = updateTime ? {createdAt: serverTimestamp()} : {};
    try {
      await setDoc(
        doc(db, collection_name, data.id),
        {...data.payload, ...payloadData},
        {
          merge: true,
        }
      );
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }

  // Fetch all coupons
  async getCoupons() {
    try {
      // console.log("API CALL =========> getCoupons:");
      const q = query(
        collection(db, collection_name),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      let proArr = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        proArr.push({id: doc.id, ...doc.data()});
      });
      // console.log("coupons:", proArr);
      return {success: true, data: proArr};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }
  // Fetch coupon by id
  async getCouponById(couponId) {
    try {
      // console.log("API CALL =========> getCoupon by id:", couponId);
      const docRef = doc(db, collection_name, couponId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("coupon data:", docSnap.data());
        return {success: true, data: docSnap.data()};
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return {success: false, message: "No Coupon Found"};
      }
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }

  async deleteCoupon(couponId) {
    try {
      await deleteDoc(doc(db, collection_name, couponId));
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }
}
