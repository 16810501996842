import React, {useState, useEffect} from "react";
import UserAddressesPage from "../../../pages/user/UserAddressesPage";

const SelectAddressStep = ({setCompleted, selAddress, setSelAddress}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  useEffect(() => {
    //console.log("selAddress in SelectAddressStep:", selAddress);
    if (selAddress !== null && selAddress !== undefined) {
      setSelectedAddress(selAddress);
      setCompleted(true);
    }
  }, []);

  useEffect(() => {
    //console.log("selectedAddress in SelectAddressStep:", selectedAddress);
    if (selectedAddress !== null) {
      setSelAddress(selectedAddress);
      // Calculate shipping
      setCompleted(true);
    }
  }, [selectedAddress]);

  return (
    <UserAddressesPage
      selectAddress={true}
      selectedAddress={selectedAddress}
      setSelectedAddress={setSelectedAddress}
    />
  );
};

export default SelectAddressStep;
