import {Alert, Slide, Snackbar} from "@mui/material";
import React, {useState} from "react";
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
const CustomisedSnackbar = ({
  message = "Customised snackbar",
  severity = "success",
  open,
  handleClose,
}) => {
  return (
    <div>
      <Snackbar
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
      >
        <Alert
          icon={false}
          onClose={handleClose}
          severity={severity}
          sx={{width: "100%"}}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomisedSnackbar;
