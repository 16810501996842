import React, {useState, useEffect, useMemo} from "react";
import AllReviewsContext from "./AllReviewsContext";

const AllReviewsState = (props) => {
  const [state, setState] = useState([]);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  useEffect(() => {
    //console.log("all reviews state:", state);
  }, [state]);
  return (
    <AllReviewsContext.Provider value={value}>
      {props.children}
    </AllReviewsContext.Provider>
  );
};

export default AllReviewsState;
