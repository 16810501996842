import { Paper } from "@mui/material";
import React from "react";
import Menu from "./CustomMenu";

const Dropdown = ({
  mobile,
  submenus,
  dropdown,
  depthLevel,
  onHandleToggle,
}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass =
    depthLevel > 1 ? `dropdown-submenu${mobile ? "-mobile" : ""}` : "";

  return (
    <Paper
      className={`dropdown${mobile ? "-mobile" : ""} ${dropdownClass} ${
        dropdown ? "show" : ""
      }`}
    >
      {submenus.map((submenu, index) => (
        <Menu
          mobile={mobile}
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          onHandleToggle={onHandleToggle}
        ></Menu>
      ))}
    </Paper>
  );
};

export default Dropdown;
