import { List, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const AppbarContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: 0,
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
}));

export const AppbarHeader = styled(Typography)(() => ({
  flexGrow: 1,
  marginTop: 0,
  fontSize: "1.8rem",
  padding: "10px",
  color: "teal",
}));

export const MyList = styled(List)(({ type }) => ({
  display: type === "row" ? "flex" : "block",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 0,
  paddingBottom: 0,
}));

export const ActionIconsContainerMobile = styled(Box)(() => ({
  display: "flex",
  background: "lightgrey",
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  alignItems: "center",
  zIndex: 99,
  // borderTop: `1px solid grey`,
}));
