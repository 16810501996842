import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, {useState, useContext, useEffect, useRef} from "react";
import CustomButton from "../../common/Button/CustomButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import "./styles.scss";
import Loader from "../../loader/Loader";
import OrderSummary from "../../app/PlaceOrder/OrderSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grow from "@mui/material/Grow";

const ViewOrder = ({detailedOrder, hideModal, onSetOrder}) => {
  const [initOrder, setInitOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState("");
  const courierPartnerRef = useRef(null);
  const courierTrackingUrlRef = useRef(null);
  const courierTrackingIdRef = useRef(null);
  //console.log("detailedOrder:", detailedOrder);

  const titleInputLimit = {
    minLength: 5,
    maxLength: 200,
  };
  useEffect(() => {
    if (initOrder.id === undefined) {
      setInitOrder({...detailedOrder});
    }
  }, [detailedOrder]);
  useEffect(() => {
    if (initOrder.id !== undefined) {
      setLoading(false);
    }
  }, [initOrder]);

  const handleChangeOrderDetail = (updateData) => {
    const _initOrder = {
      ...initOrder,
      ...updateData,
    };
    setInitOrder({..._initOrder});
  };
  const onClickUpdate = () => {
    console.log("init order now:", initOrder);
    let dataToSend = initOrder;
    if (initOrder.status === "Shipped") {
      if (courierPartnerRef.current.value.length < 2) {
        setFormError("Please enter courier partner.");
        courierPartnerRef.current.focus();
        return;
      }
      if (courierTrackingUrlRef.current.value.length < 2) {
        setFormError("Please enter courier tracking url.");
        courierTrackingUrlRef.current.focus();
        return;
      }
      if (courierTrackingIdRef.current.value.length < 2) {
        setFormError("Please enter courier tracking id.");
        courierTrackingIdRef.current.focus();
        return;
      }
      dataToSend = {
        ...initOrder,
        shippedAt: new Date().toString(),
      };
    }
    if (initOrder.status === "Delivered") {
      dataToSend = {
        ...initOrder,
        deliveredAt: new Date().toString(),
      };
    }
    if (initOrder.status === "Out for delivery") {
      dataToSend = {
        ...initOrder,
        ofdAt: new Date().toString(),
      };
    }
    if (
      initOrder.status === "Cancelled by buyer" ||
      initOrder.status === "Cancelled by seller"
    ) {
      dataToSend = {
        ...initOrder,
        cancelledAt: new Date().toString(),
      };
    }
    if (initOrder.status === "Refunded") {
      dataToSend = {
        ...initOrder,
        refundedAt: new Date().toString(),
      };
    }
    onSetOrder(dataToSend);
  };
  return (
    <Grid container spacing={2}>
      {loading && (
        <Grid item xs={12}>
          <Loader type="CIRCULAR" />
        </Grid>
      )}
      {!loading && (
        <>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{color: "darkred"}}>
              {formError}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="orderId"
              label="Order Id"
              variant="standard"
              value={initOrder.id}
              aria-readonly
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="status">Select status</InputLabel>
              <Select
                labelId="status"
                id="status"
                value={initOrder.status}
                label="Select status"
                onChange={(e) =>
                  handleChangeOrderDetail({
                    status: e.target.value,
                  })
                }
                size="medium"
              >
                <MenuItem value="Placed">Placed</MenuItem>
                <MenuItem value="Shipped">Shipped</MenuItem>
                <MenuItem value="Out for delivery">Out for delivery</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
                <MenuItem value="Cancelled by buyer">
                  Cancelled by buyer
                </MenuItem>
                <MenuItem value="Cancelled by seller">
                  Cancelled by seller
                </MenuItem>
                <MenuItem value="Refunded">Refunded</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            sx={{display: initOrder.status === "Shipped" ? "flex" : "none"}}
          >
            <Grow in={initOrder.status === "Shipped"}>
              <TextField
                inputRef={courierPartnerRef}
                fullWidth
                id="courier-partner"
                label="Courier Partner"
                variant="standard"
                value={
                  initOrder.courierDetails && initOrder.courierDetails.partner
                    ? initOrder.courierDetails.partner
                    : ""
                }
                onChange={(e) =>
                  handleChangeOrderDetail({
                    courierDetails: {
                      ...initOrder.courierDetails,
                      partner: e.target.value,
                    },
                  })
                }
                inputProps={titleInputLimit}
              />
            </Grow>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{display: initOrder.status === "Shipped" ? "flex" : "none"}}
          >
            <Grow
              in={initOrder.status === "Shipped"}
              style={{transformOrigin: "0 0 0"}}
              {...(initOrder.status === "Shipped" ? {timeout: 500} : {})}
            >
              <TextField
                inputRef={courierTrackingUrlRef}
                fullWidth
                id="tracking-url"
                label="Tracking Url"
                variant="standard"
                value={
                  initOrder.courierDetails &&
                  initOrder.courierDetails.trackingUrl
                    ? initOrder.courierDetails.trackingUrl
                    : ""
                }
                onChange={(e) =>
                  handleChangeOrderDetail({
                    courierDetails: {
                      ...initOrder.courierDetails,
                      trackingUrl: e.target.value,
                    },
                  })
                }
                inputProps={titleInputLimit}
              />
            </Grow>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{display: initOrder.status === "Shipped" ? "flex" : "none"}}
          >
            <Grow
              in={initOrder.status === "Shipped"}
              style={{transformOrigin: "0 0 0"}}
              {...(initOrder.status === "Shipped" ? {timeout: 1000} : {})}
            >
              <TextField
                inputRef={courierTrackingIdRef}
                fullWidth
                id="tracking-id"
                label="Tracking Id"
                variant="standard"
                value={
                  initOrder.courierDetails &&
                  initOrder.courierDetails.trackingId
                    ? initOrder.courierDetails.trackingId
                    : ""
                }
                onChange={(e) =>
                  handleChangeOrderDetail({
                    courierDetails: {
                      ...initOrder.courierDetails,
                      trackingId: e.target.value,
                    },
                  })
                }
                inputProps={titleInputLimit}
              />
            </Grow>
          </Grid>
          {/* Order Summary */}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="order-content"
                id="order-header"
              >
                Order Summary (
                {initOrder.items.length > 1 ? (
                  <span>{initOrder.items.length} different products</span>
                ) : (
                  <span>1 Product</span>
                )}{" "}
                )
              </AccordionSummary>
              <AccordionDetails>
                <OrderSummary
                  items={initOrder.items}
                  max_width="md"
                  no_wrap={false}
                  order={initOrder}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Payment Details */}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="order-content"
                id="order-header"
              >
                Payment Details ({initOrder.payment.method})
              </AccordionSummary>
              <AccordionDetails>
                {initOrder.payment.method !== "cod" && (
                  <>
                    <label style={{fontWeight: 500}}>Method: </label>
                    {initOrder.payment.method}
                    <br />
                    <label style={{fontWeight: 500}}>Status: </label>
                    Payment {initOrder.payment.status}
                    <br />
                    <label style={{fontWeight: 500}}>Payment Id: </label>
                    {initOrder.payment.payment_id}
                    <br />
                    <label style={{fontWeight: 500}}>Attempted at: </label>
                    {initOrder.payment.attempted_at}
                  </>
                )}
                {initOrder.payment.method === "cod" && (
                  <>
                    <label style={{fontWeight: 500}}>Method: </label>
                    Cash on delivery
                    <br />
                    <label style={{fontWeight: 500}}>Status: </label>
                    Payment {initOrder.payment.status}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Buyer Details */}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="buyer-content"
                id="buyer-header"
              >
                Buyer Detail
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <h4 className="buyer-details-h">Account Detail</h4>
                  <p className="buyer-details-p">Id: {initOrder.user.id}</p>
                  <p className="buyer-details-p">{initOrder.user.name}</p>
                  <p className="buyer-details-p">{initOrder.user.email}</p>
                  <h4 className="buyer-details-h">Shipping address</h4>
                  <p className="buyer-details-p">{initOrder.address.name}</p>
                  <p className="buyer-details-p">
                    {initOrder.address.mobile}
                    {initOrder.address.alternateMobile !== "" && (
                      <span>, {initOrder.address.alternateMobile}</span>
                    )}
                  </p>
                  <p className="buyer-details-p">
                    {initOrder.address.address},<br />
                    {initOrder.address.city}, {initOrder.address.state}-
                    {initOrder.address.pincode}
                  </p>
                  {initOrder.address.landmark !== "" && (
                    <p className="buyer-details-p">
                      Landmark: {initOrder.address.landmark}
                    </p>
                  )}
                </>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Shipment Details */}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="buyer-content"
                id="buyer-header"
              >
                Shipment Detail
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <h4 className="buyer-details-h">
                    Courier Partner:{" "}
                    {initOrder.courierDetails !== undefined
                      ? initOrder.courierDetails.partner
                      : ""}
                  </h4>
                  <p className="buyer-details-p">
                    Tracking Id:{" "}
                    {initOrder.courierDetails !== undefined
                      ? initOrder.courierDetails.trackingId
                      : ""}
                  </p>
                  <p className="buyer-details-p">
                    Tracking URL:{" "}
                    {initOrder.courierDetails !== undefined ? (
                      <a
                        href={initOrder.courierDetails.trackingUrl}
                        target="blank"
                      >
                        {initOrder.courierDetails.trackingUrl}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <h4 className="buyer-details-h">Shipping address</h4>
                  <p className="buyer-details-p">{initOrder.address.name}</p>
                  <p className="buyer-details-p">
                    {initOrder.address.mobile}
                    {initOrder.address.alternateMobile !== "" && (
                      <span>, {initOrder.address.alternateMobile}</span>
                    )}
                  </p>
                  <p className="buyer-details-p">
                    {initOrder.address.address}
                    {initOrder.address.landmark !== "" && (
                      <span>, Landmark: {initOrder.address.landmark}</span>
                    )}
                    , {initOrder.address.city}, {initOrder.address.state}-
                    {initOrder.address.pincode}
                  </p>
                </>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Buttons */}
          <Grid item xs={6}>
            <CustomButton
              variant="outlined"
              color="primary"
              size="small"
              onClick={hideModal}
              fullWidth
              sx={{marginTop: "15px"}}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              variant="contained"
              color="primary"
              size="small"
              onClick={onClickUpdate}
              fullWidth
              sx={{marginTop: "15px"}}
            >
              Update Status
            </CustomButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ViewOrder;
