import React, { useState, useMemo } from "react";
import AddCategoryContext from "./AddCategoryContext";

const AddCategoryState = (props) => {
  const initState = {
    id: "",
    name: "",
    parentCategory: {
      id: "None",
      name: "None",
    },
    images: [],
    showInNavbar: true,
  };
  const [state, setState] = useState(initState);
  // useEffect(() => {
  //   console.log("Add Category state:", state);
  // }, [state]);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  return (
    <AddCategoryContext.Provider value={value}>
      {props.children}
    </AddCategoryContext.Provider>
  );
};

export default AddCategoryState;
