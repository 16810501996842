import {Container, Typography} from "@mui/material";
import React from "react";

const PageNotFound = () => {
  return (
    <Container maxWidth="lg" sx={{height: "100vh"}}>
      <Typography variant="h5" component="p" mt={2} textAlign="center">
        Page does not exist
      </Typography>
    </Container>
  );
};

export default PageNotFound;
