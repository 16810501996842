import {
  Avatar,
  AvatarGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import SearchBar from "../../components/common/SearchBar";
import UserProfileContext from "../../context/userProfile/UserProfileContext";
import OrdersAPI from "../../api/firebase/OrdersAPI";
import Loader from "../../components/loader/Loader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import ProductsContainer from "../../components/app/Products/ProductsContainer";
import AllProductContext from "../../context/allProducts/AllProductsContext";
import RecentlyViewedContext from "../../context/recentlyViewed/RecentlyViewedContext";
import CustomButton from "../../components/common/Button/CustomButton";

const UserOrdersPage = () => {
  const userProfileContext = useContext(UserProfileContext);
  const allProductsContext = useContext(AllProductContext);
  const recentlyViewedContext = useContext(RecentlyViewedContext);
  const recentlyViewedItemsArray = recentlyViewedContext.state;
  const [orders, setOrders] = useState([]);
  const [detailedOrders, setDetailedOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const ordersAPI = new OrdersAPI();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfileContext.state !== null) {
      setUser(userProfileContext.state);
    }

    if (
      userProfileContext.state !== null &&
      userProfileContext.state.orders !== undefined
    ) {
      let sortedOrders = userProfileContext.state.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      // console.log("sortedOrders:", sortedOrders);
      setOrders(sortedOrders);
    } else {
      setOrders([]);
    }
  }, [userProfileContext.state]);

  useEffect(() => {
    let orderDetail = [];

    if (orders.length > 0) {
      setLoading(true);
      orders.map(async (order, index) => {
        // console.log("order id:", order.id);
        await ordersAPI.getOrderDetailById(order.id).then((res) => {
          //console.log("getOrderDetailById RES:", res);
          orderDetail.push(res);
          if (index === orders.length - 1) {
            setDetailedOrders(orderDetail);
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }
        });
      });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [orders]);

  useEffect(() => {
    console.log("detailedOrders CHANGED:", detailedOrders);

    setFilteredOrders(detailedOrders);
    if (detailedOrders.length !== 0) {
      setTimeout(() => {}, 500);
    }
  }, [detailedOrders]);
  useEffect(() => {
    console.log("filteredOrders CHANGED:", filteredOrders);
  }, [filteredOrders]);

  function onSearchTextChange(e) {
    const enteredValue = e.currentTarget.value;
    console.log("search text:", enteredValue);
    setSearchText(enteredValue);
    if (enteredValue.length > 2) {
      // filter orders based on search text
      const filtered = detailedOrders.filter((order) => {
        let allTitles = "";
        order.items.map((item, index) => {
          allTitles = allTitles + " " + item.title;
        });
        return allTitles.toLowerCase().includes(enteredValue.toLowerCase());
      });
      setFilteredOrders(filtered);
    } else {
      filteredOrders.length !== detailedOrders.length &&
        setFilteredOrders(detailedOrders);
    }
  }
  return (
    <>
      {!loading && (
        <Container
          maxWidth={detailedOrders.length > 0 ? "md" : "lg"}
          sx={{marginTop: "2rem"}}
        >
          {detailedOrders.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Your Orders</Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchBar
                  placeholderText="Search your orders..."
                  variant="border"
                  onSearchTextChange={onSearchTextChange}
                  searchText={searchText}
                />
              </Grid>
            </Grid>
          )}

          {!loading &&
            filteredOrders.length > 0 &&
            filteredOrders.map((order, index) => {
              let date = new Date(order.createdAt);
              let ex_date = date.setDate(date.getDate() + 8);
              let expectedDD = new Date(ex_date)
                .toDateString()
                .substring(0, 10);
              let label = "";
              switch (order.status) {
                case "Placed":
                  label = "Delivery expected by " + expectedDD;
                  break;
                case "Shipped":
                  label = "On the way";
                  break;
                case "Out for delivery":
                  label = "Out for delivery";
                  break;
                case "Delivered":
                  label = "Delivered on " + order.deliveredAt.substring(0, 10);
                  break;
                case "Cancelled by buyer":
                  label = "Cancelled on " + order.cancelledAt.substring(0, 10);
                  break;
                case "Cancelled by seller":
                  label =
                    "Cancelled by seller on " +
                    order.cancelledAt.substring(0, 10);
                  break;
              }
              return (
                <Paper
                  key={index}
                  className="order-list-paper"
                  onClick={() =>
                    navigate("/orderDetail", {state: {order: order}})
                  }
                >
                  <Grid
                    container
                    spacing={1}
                    textAlign="left"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {order.items.length > 1 && (
                      <Grid item xs={4} sm={3} sx={{display: "flex"}}>
                        <AvatarGroup max={2} variant="rounded">
                          {order.items.map((item, id) => (
                            <Avatar
                              key={id}
                              alt={item.id}
                              src={item.images[0].url}
                              className="order-item-avatar"
                            />
                          ))}
                        </AvatarGroup>
                      </Grid>
                    )}
                    {order.items.length === 1 && (
                      <Grid item xs={4} sm={3} sx={{display: "flex"}}>
                        <Avatar
                          key={order.items[0].id}
                          alt={order.items[0].title}
                          src={order.items[0].images[0].url}
                          sx={{width: "-webkit-fill-available", height: "auto"}}
                          variant="square"
                        />
                      </Grid>
                    )}
                    <Grid item xs={7} sm={7}>
                      <Typography
                        variant="body2"
                        component="p"
                        sx={{fontWeight: 400, fontSize: "0.8rem"}}
                        className="resp-order-item-body2"
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        gutterBottom={true}
                        noWrap={true}
                        className="resp-order-item-caption"
                      >
                        {order.items.map((item, index) => {
                          let text = item.title;
                          if (index < order.items.length - 1) {
                            text += ", ";
                          }

                          return text;
                        })}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      display={{xs: "none", sm: "block", md: "block"}}
                      textAlign="center"
                    >
                      <Typography
                        variant="body2"
                        className="resp-order-item-body2"
                      >
                        &#8377;{order.amount}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} textAlign="right">
                      <IconButton>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}

          {!loading &&
            filteredOrders.length === 0 &&
            detailedOrders.length > 0 && (
              <Typography variant="body1" component="p" padding="1rem 0">
                No results found
              </Typography>
            )}
          {!loading && detailedOrders.length === 0 && (
            <>
              <Grid
                container
                item
                spacing={2}
                sx={{marginTop: "1rem"}}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography variant="h6" textAlign="center" padding="1rem">
                    Not placed any order yet ?
                  </Typography>
                </Grid>
                {user.cartItems !== undefined &&
                  user.cartItems !== null &&
                  user.cartItems.length > 0 && (
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="body1"
                        component="p"
                        textAlign="center"
                        padding="1rem"
                      >
                        You have items in your cart
                      </Typography>
                      <CustomButton
                        variant="contained"
                        fullWidth={true}
                        onClick={() => navigate("/cart")}
                      >
                        Go to Cart
                      </CustomButton>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ProductsContainer
                    heading="Consider these products"
                    showInRow={true}
                    addToCartEnable={true}
                    showCarousel={false}
                    productsArray={allProductsContext.state}
                  />
                </Grid>
                {/* Recently Viewed Items */}
                {recentlyViewedItemsArray.length > 0 && (
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductsContainer
                        heading="Recently Viewed"
                        showInRow={true}
                        addToCartEnable={true}
                        showCarousel={false}
                        productsArray={recentlyViewedItemsArray}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Container>
      )}
      {loading && <Loader type="LINEAR" />}
    </>
  );
};

export default UserOrdersPage;
