import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import GridViewIcon from "@mui/icons-material/GridView";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import WebIcon from "@mui/icons-material/Web";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
export const drawerItems = [
  {
    id: 0,
    icon: <WebIcon />,
    label: "Go to Website",
    route: "/",
  },
  {
    id: 1,
    icon: <DashboardIcon />,
    label: "Dashboard",
    route: "/admin/dashboard",
  },
  {
    id: 2,
    icon: <GridViewIcon />,
    label: "Products",
    route: "/admin/products",
  },
  {
    id: 3,
    icon: <CategoryIcon />,
    label: "Categories",
    route: "/admin/categories",
  },
  {
    id: 4,
    icon: <ShoppingCartIcon />,
    label: "Orders",
    route: "/admin/orders",
  },
  {
    id: 5,
    icon: <PeopleIcon />,
    label: "Users",
    route: "/admin/users",
  },
  {
    id: 6,
    icon: <LocalOfferIcon />,
    label: "Coupons",
    route: "/admin/coupons",
  },
];
