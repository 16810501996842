import React, {useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";

const ImageCarousel = ({
  autoPlayOnHover = false,
  showThumbs = false,
  showIndicators = false,
  ImagesArr = [],
  autoPlay = false,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        infiniteLoop={true}
        interval={autoPlayOnHover ? 1000 : 3000}
        autoPlay={autoPlayOnHover ? isHovering : autoPlay}
        showArrows={autoPlayOnHover ? false : true}
        showStatus={false}
        showThumbs={showThumbs}
        showIndicators={showIndicators}
        emulateTouch={true}
      >
        {ImagesArr.map((image, index) => (
          <div key={index}>
            <img src={image} loading="lazy" alt={`Image ${index + 1}`} />
            {/* <p className="legend">Legend</p> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
