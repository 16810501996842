import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {ArrowBackSharp, ArrowForwardSharp} from "@mui/icons-material";

const HorizontalLinearStepper = ({steps = [], activeStep = 0, handleStep}) => {
  return (
    <Box sx={{width: "100%", height: activeStep === 2 ? "95%" : "100%"}}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (step.subtitle !== undefined) {
            labelProps.optional = (
              <Typography
                variant="caption"
                noWrap
                sx={{width: "150px !important", display: "block"}}
              >
                {step.subtitle}
              </Typography>
            );
          }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{mt: 2, mb: 1}}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
            <Box sx={{flex: "1 1 auto"}} />
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </Box>
        </>
      ) : (
        <>
          <div
            style={{
              height: activeStep === 2 ? "60vh" : "65vh",
              overflowY: "overlay",
            }}
          >
            {steps[activeStep].component}
          </div>

          <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
            {activeStep > 0 && (
              <Button
                color="inherit"
                onClick={() => handleStep(activeStep - 1)}
                sx={{mr: 1}}
                startIcon={<ArrowBackSharp />}
              >
                Back
              </Button>
            )}
            <Box sx={{flex: "1 1 auto"}} />
            {steps[activeStep].isCompleted && (
              <Button
                variant="contained"
                endIcon={<ArrowForwardSharp />}
                onClick={() => handleStep(activeStep + 1)}
              >
                Proceed
              </Button>
            )}
            {!steps[activeStep].isCompleted &&
              activeStep !== steps.length - 1 && (
                <Button disabled endIcon={<ArrowForwardSharp />}>
                  Proceed
                </Button>
              )}
          </Box>
        </>
      )}
    </Box>
  );
};
export default HorizontalLinearStepper;
