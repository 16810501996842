import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
// Icons
import FavoriteIcon from "@mui/icons-material/Favorite";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import {useNavigate} from "react-router-dom";
////
//Context
const TabButton = () => {
  return (
    <button className="tab-button">
      <HomeOutlinedIcon />
      <span className="tab-button-label">Home</span>
    </button>
  );
};
export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
    switch (value) {
      case "home":
        navigate("/");
        break;
      case "categories":
        navigate("/collections");
        break;
      case "wishlist":
        navigate("/myWishlist");
        break;
      case "orders":
        navigate("/userOrders");
        break;
      case "cart":
        navigate("/cart");
        break;
      default:
        break;
    }
  }, [value]);

  return (
    <Box sx={{p: 1}} ref={ref}>
      <CssBaseline />

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {/* <TabButton /> */}
          <BottomNavigationAction
            sx={{minWidth: "70px"}}
            label="Home"
            value="home"
            icon={<HomeOutlinedIcon />}
          />
          <BottomNavigationAction
            sx={{minWidth: "70px"}}
            label="Categories"
            value="categories"
            icon={<CategoryOutlinedIcon />}
          />
          <BottomNavigationAction
            sx={{minWidth: "70px"}}
            label="Wishlist"
            value="wishlist"
            icon={<FavoriteIcon />}
          />
          <BottomNavigationAction
            sx={{minWidth: "70px"}}
            label="Orders"
            value="orders"
            icon={<FormatListBulletedOutlinedIcon />}
          />
          <BottomNavigationAction
            sx={{minWidth: "70px"}}
            label="Cart"
            value="cart"
            icon={<ShoppingCartOutlinedIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
