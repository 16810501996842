import {Container, Typography} from "@mui/material";
import React from "react";

const NoContent = () => {
  return (
    <Typography variant="h5" textAlign="center" component="p" mt="4rem">
      No Items Found
    </Typography>
  );
};

export default NoContent;
