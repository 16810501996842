import {TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import CustomButton from "../../common/Button/CustomButton";
import Grid2 from "@mui/material/Unstable_Grid2/";
import AllCouponsContext from "../../../context/allCoupons/AllCouponsContext";
import AddCouponContext from "../../../context/addCoupon/AddCouponContext";

const AddCoupon = ({hideModal, handleSetCoupon, action}) => {
  const allCouponsContext = useContext(AllCouponsContext);
  const couponContext = useContext(AddCouponContext);
  const allCouponsArr = allCouponsContext.state;
  console.log("allCouponsArr:", allCouponsArr);
  console.log("couponContext:", couponContext.state);
  const titleInputLimit = {
    minLength: 2,
    maxLength: 10,
  };
  const [errorMsg, setErrorMsg] = useState("");
  const checkDuplicateEntry = (code) => {
    const chk = allCouponsArr.filter((coupon) => coupon.couponCode === code);
    if (chk.length > 0) {
      setErrorMsg("Coupon Already Exists!!");
    } else {
      setErrorMsg("");
    }
  };
  useEffect(() => {
    if (action === "ADD") {
      checkDuplicateEntry(couponContext.state.couponCode);
    }
  }, []);

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12} sm={6} mb={2}>
        <TextField
          required
          fullWidth
          id="couponCode"
          label="Coupon Code"
          variant="standard"
          value={couponContext.state.couponCode}
          onChange={(e) => {
            checkDuplicateEntry(e.target.value.trim().toUpperCase());
            couponContext.setState({
              ...couponContext.state,
              couponCode: e.target.value.trim().toUpperCase(),
              id: e.target.value.trim().replace(/ /g, "_").toUpperCase(),
            });
          }}
          inputProps={titleInputLimit}
          disabled={action === "EDIT" ? true : false}
          error={errorMsg !== ""}
          helperText={errorMsg}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} mb={2}>
        <TextField
          required
          fullWidth
          id="amount"
          label="Amount Off"
          variant="standard"
          value={couponContext.state.amount}
          onChange={(e) => {
            if (isNaN(e.target.value) || e.target.value < 0) {
              return;
            }
            couponContext.setState({
              ...couponContext.state,
              amount: Number(e.target.value),
            });
          }}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} mb={2}>
        <TextField
          required
          fullWidth
          id="minShopping"
          label="Minimum Shopping"
          variant="standard"
          value={couponContext.state.minShopping}
          onChange={(e) => {
            if (isNaN(e.target.value) || e.target.value < 0) {
              return;
            }
            couponContext.setState({
              ...couponContext.state,
              minShopping: Number(e.target.value),
            });
          }}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} mb={2}>
        <TextField
          required
          fullWidth
          id="maxUses"
          label="Max. no. of uses"
          variant="standard"
          value={couponContext.state.maxUses}
          onChange={(e) => {
            if (isNaN(e.target.value) || e.target.value < 0) {
              return;
            }
            couponContext.setState({
              ...couponContext.state,
              maxUses: Number(e.target.value),
            });
          }}
        />
      </Grid2>
      <CustomButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          handleSetCoupon(action);
        }}
        fullWidth
        disabled={
          errorMsg !== "" ||
          couponContext.state.couponCode === "" ||
          Number(couponContext.state.amount) < 1 ||
          couponContext.state.minShopping === "" ||
          Number(couponContext.state.maxUses) < 1
        }
      >
        {action === "ADD" ? "Add Coupon" : "Update Coupon"}
      </CustomButton>
      {action === "EDIT" && (
        <CustomButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={hideModal}
          fullWidth
          sx={{marginTop: "15px"}}
        >
          Cancel
        </CustomButton>
      )}
    </Grid2>
  );
};

export default AddCoupon;
