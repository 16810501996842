import {
  Breadcrumbs,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {useState, useEffect} from "react";
import ProductCard from "./ProductCard";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import NoContent from "../../../pages/errors/NoContent";
const ProductsContainer = ({
  heading = "",
  showInRow = false,
  addToCartEnable = false,
  productsArray = [],
  showCarousel = true,
  fadeOutOnRemovingLike = false,
  useMobileSize = false,
  showLikeButton = true,
  cancelSearchHandle = () => {},
  showScrollButtons = false,
  showRating = true,
  showRatingNumber = true,
  showQuantity = false,
  breadcrumbs = null,
}) => {
  //console.log("productsArray:", productsArray);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const responsiveSize = mobileScreen || useMobileSize ? "130px" : "250px";
  const idOfScrollDiv = `slide-div-` + heading.replace(/ /g, "-");
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  useEffect(() => {
    if (showScrollButtons) {
      const slideDiv = document.getElementById(idOfScrollDiv);
      const scrollW = slideDiv.scrollWidth;
      const offsetW = slideDiv.offsetWidth;
      //console.log("scrollW:", scrollW, "__offsetW:", offsetW);
      if (scrollW === offsetW) {
        setShowRightArrow(false);
      }
      slideDiv.addEventListener("scroll", () => {
        const slideDiv = document.getElementById(idOfScrollDiv);
        const scrollL = slideDiv.scrollLeft;
        const offsetW = slideDiv.offsetWidth;
        const scrollW = slideDiv.scrollWidth;
        if (scrollL !== 0) {
          setShowLeftArrow(true);
        } else {
          setShowLeftArrow(false);
        }
        if (scrollW - offsetW - scrollL < 1) {
          setShowRightArrow(false);
        } else {
          setShowRightArrow(true);
        }
      });
    }
  }, [productsArray]);

  //ScrollRight Function
  const scrollRight = () => {
    //Scroll div to Right
    const slideDiv = document.getElementById(idOfScrollDiv);
    const offsetWidth = slideDiv.offsetWidth;
    const stepValue = offsetWidth - 5;
    slideDiv.scrollLeft = slideDiv.scrollLeft + stepValue;
  };
  //ScrollLeft Function
  const scrollLeft = () => {
    //Scroll div to Left
    const slideDiv = document.getElementById(idOfScrollDiv);
    const offsetWidth = slideDiv.offsetWidth;
    const stepValue = offsetWidth - 5;
    slideDiv.scrollLeft = slideDiv.scrollLeft - stepValue;
  };
  return (
    <div className="products-container-div">
      {breadcrumbs !== null && (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      )}

      {heading !== "" && productsArray.length > 0 && (
        <Typography
          variant="h5"
          component="div"
          sx={{mt: "2rem", mb: "2rem", textAlign: "center"}}
          className="wow fadeIn"
        >
          {heading}
        </Typography>
      )}

      {showInRow && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          {showScrollButtons && (
            <Grid
              item
              xs={1}
              sx={{textAlign: mobileScreen ? "left" : "center"}}
            >
              {showLeftArrow && productsArray.length > 2 && (
                <IconButton
                  onClick={() => scrollLeft()}
                  sx={{padding: mobileScreen ? 0 : "0.5rem"}}
                >
                  <KeyboardArrowLeftOutlinedIcon
                    sx={{fontSize: mobileScreen ? 20 : 30}}
                  />
                </IconButton>
              )}
            </Grid>
          )}
          <Grid item xs={showScrollButtons ? 10 : 12}>
            <ImageList
              sx={{
                gridAutoFlow: "column",
                gridTemplateColumns: `repeat(auto-fill,minmax(${responsiveSize}, 0fr)) !important`,
                gridAutoColumns: `minmax(${responsiveSize}, 0fr)`,
                gap: "1rem !important",
                paddingRight: useMobileSize ? "1rem" : "",
                scrollBehavior: "smooth",
              }}
              className="no-scrollbar"
              id={`slide-div-` + heading.replace(/ /g, "-")}
            >
              {productsArray.map((product, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => {
                    cancelSearchHandle();
                  }}
                >
                  <ProductCard
                    link={`/collections/${product.parentCategory.id}/${product.id}`}
                    product={product}
                    addToCartButton={addToCartEnable}
                    showCarousel={showCarousel}
                    fadeOutOnRemovingLike={fadeOutOnRemovingLike}
                    showLikeButton={showLikeButton}
                    useMobileSize={useMobileSize}
                    showRating={showRating}
                    showRatingNumber={showRatingNumber}
                    showQuantity={showQuantity}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          {showScrollButtons && (
            <Grid
              item
              xs={1}
              sx={{textAlign: mobileScreen ? "right" : "center"}}
            >
              {showRightArrow && productsArray.length > 2 && (
                <IconButton
                  onClick={() => scrollRight()}
                  sx={{padding: mobileScreen ? 0 : "0.5rem"}}
                >
                  <KeyboardArrowRightOutlinedIcon
                    sx={{fontSize: mobileScreen ? 20 : 30}}
                  />
                </IconButton>
              )}
            </Grid>
          )}
        </Grid>
      )}

      {!showInRow && (
        <Grid
          container
          spacing={{xs: 2, md: 4}}
          columns={{xs: 4, sm: 9, md: 12}}
          justifyContent="center"
          alignItems="center"
          sx={{padding: "0 1rem"}}
          id={`slide-div-` + heading.replace(/ /g, "-")}
        >
          {productsArray.length > 0 &&
            productsArray.map((product, index) => (
              <Grid
                item
                xs={2}
                sm={3}
                md={3}
                key={index}
                // className="wow fadeIn"
                // data-wow-delay={`${index * 0.1}s`}
              >
                <ProductCard
                  link={`/collections/${product.parentCategory.id}/${product.id}`}
                  product={product}
                  addToCartButton={addToCartEnable}
                  showCarousel={showCarousel}
                  fadeOutOnRemovingLike={fadeOutOnRemovingLike}
                  showLikeButton={showLikeButton}
                  showRating={showRating}
                  useMobileSize={useMobileSize}
                  showQuantity={showQuantity}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {productsArray.length === 0 && (
        <Grid item xs={12}>
          <NoContent />
        </Grid>
      )}
    </div>
  );
};

export default ProductsContainer;
