import React, {useState, useMemo} from "react";
import AllCategoriesContext from "./AllCategoriesContext";

const AllCategoriesState = (props) => {
  const [state, setState] = useState([]);
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  // console.log("all Categories in state:", state);
  return (
    <AllCategoriesContext.Provider value={value}>
      {props.children}
    </AllCategoriesContext.Provider>
  );
};

export default AllCategoriesState;
