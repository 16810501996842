import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const labels = {
  0.5: "Bad",
  1: "Bad",
  1.5: "Not Good",
  2: "Not Good",
  2.5: "Average",
  3: "Above average",
  3.5: "Good",
  4: "Very Good",
  4.5: "Great",
  5: "Excellent",
};
const StarRating = ({
  rating,
  showTotalNumber = 0,
  showLabel = false,
  showRatingNumber = true,
}) => {
  const value = Number(rating);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name="text-feedback"
        value={value}
        readOnly
        precision={0.5}
        emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit" />}
      />
      {showRatingNumber && showTotalNumber > 0 && rating ? (
        <Box sx={{fontSize: "1rem", marginLeft: "0.5rem"}}>
          ({showTotalNumber})
        </Box>
      ) : (
        ""
      )}
      {/* {rating === undefined && (
        <Box sx={{ fontSize: "0.5rem", marginLeft: "0.5rem" }}>
          {" "}
          No ratings yet
        </Box>
      )} */}
      {showLabel ? <Box sx={{ml: 2}}>{labels[value]}</Box> : ""}
    </Box>
  );
};

export default StarRating;
