import React, {useState, useRef} from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Link} from "react-router-dom";
import AuthAPI from "../../../api/firebase/AuthAPI";
import Alert from "@mui/material/Alert";
import {Collapse} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../../common/Button/CustomButton";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/" className="link bold copyright-appname">
        {process.env.REACT_APP_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function Terms(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="left" {...props}>
      By Continuing, you agree to {process.env.REACT_APP_NAME}'s{" "}
      <Link to="#" className="link bold">
        Terms and Conditions
      </Link>
      , and{" "}
      <Link to="#" className="link bold">
        Privacy Policy
      </Link>
    </Typography>
  );
}
const authAPI = new AuthAPI();
export default function SignUpForm() {
  const [error, setError] = useState("");
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const c_passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const name = data.get("name");
    const email = data.get("email");
    const password = data.get("password");
    const confirmPassword = data.get("confirm-password");

    if (password === confirmPassword) {
      authAPI.signUpWithEmailPassword(name, email, password).then((res) => {
        setLoading(false);
        if (!res.status) {
          let errMsg = res.error.message;
          if (res.error.code === "auth/email-already-in-use") {
            errMsg = "Email already in use";
            emailRef.current.focus();
          } else if (res.error.code === "auth/invalid-email") {
            errMsg = "Invalid Email address";
            emailRef.current.focus();
          } else if (res.error.code === "auth/weak-password") {
            errMsg = "Password should be at least 6 characters";
            passwordRef.current.focus();
          }
          setError(errMsg);
        }
      });
    } else {
      setLoading(false);
      setError("Password doesn't match");
      c_passwordRef.current.focus();
    }
    //
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{color: "#fff", zIndex: 1}}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}

        <Avatar sx={{m: 1, bgcolor: "primary.main"}}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
          <Collapse in={error !== ""}>
            <Alert sx={{margin: "10px 0 20px 0"}} severity="error">
              {error}
            </Alert>
          </Collapse>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="given-name"
                name="name"
                required
                fullWidth
                id="name"
                label="Full Name"
                inputRef={nameRef}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                inputRef={emailRef}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                inputRef={passwordRef}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirm-password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                autoComplete="confirm-password"
                inputRef={c_passwordRef}
              />
            </Grid>
            {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
          </Grid>
          <Terms />
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Sign Up
          </CustomButton>
          {/* <Grid container justifyContent="flex-end">
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                <Link to="/login" className="link">
                  Already have an account? Sign in
                </Link>
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{mt: 1}} />
    </Container>
  );
}
