import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import {useLocation} from "react-router-dom";
// import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

export const ScrollToTop = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
