/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useContext, useEffect} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/common/Button/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import CouponsAPI from "../../../api/firebase/CouponsAPI";
import {Avatar, Snackbar, Typography} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AddCoupon from "../../../components/admin/add-coupon/AddCoupon";
import AllCouponsContext from "../../../context/allCoupons/AllCouponsContext";
import {styles} from "./styles";
import CustomDataGrid from "../../../components/common/DataGrid/CustomDataGrid";
import DataGridActions from "../../../components/admin/datagrid-actions/DataGridActions";
import AddCouponContext from "../../../context/addCoupon/AddCouponContext";
import ImageIcon from "@mui/icons-material/Image";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const CouponsListAdmin = () => {
  const initState = {
    id: "",
    couponCode: "",
    minShopping: "",
    maxUses: "",
    amount: "",
    isActive: true,
    users: [],
  };
  const couponAPI = new CouponsAPI();
  const allCouponsContext = useContext(AllCouponsContext);
  console.log("all Coupons :", allCouponsContext.state);

  // Fetch all coupons
  useEffect(() => {
    const couponAPI = new CouponsAPI();
    const resCoupons = couponAPI.getCoupons();
    resCoupons.then((resData) => {
      // console.log("received:", resData);
      if (!resData) {
        return;
      }
      console.log("Done fetching all coupons: ", resData);
      allCouponsContext.setState(resData.data);
    });
  }, []);

  const couponContext = useContext(AddCouponContext);

  // For Add Coupon Modal
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const showAddCouponModal = (action) => {
    if (action === "ADD" && editCouponModal) {
      setEditCouponModal(false);
      couponContext.setState(initState);
    }
    setOpenCouponModal(true);
  };
  const hideAddCouponModal = () => setOpenCouponModal(false);

  // For snackbar
  const [snackbarState, setSnackbarState] = useState(false);
  const [message, setMessage] = useState({text: "", type: ""});
  const showSnackbar = () => {
    setSnackbarState(true);
  };
  const hideSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState(false);
  };

  //// set edit coupon
  const [editCouponModal, setEditCouponModal] = useState(false);

  const setEditCoupon = (coupon) => {
    console.log("edit coupon:", coupon);
    setEditCouponModal(true);
    couponContext.setState(coupon);
    showAddCouponModal("EDIT");
  };
  // Delete Coupon from firebase and update allCouponsContext state
  // const handleDeleteCoupon = (cat_id) => {
  //   const res = couponAPI.deleteCoupon(cat_id);
  //   res
  //     .then((resData) => {
  //       if (resData.success) {
  //         setMessage({
  //           text: "Coupon deleted successfully !",
  //           type: "success",
  //         });
  //         showSnackbar();
  //         const couponIndex = allCouponsContext.state.findIndex(
  //           (obj) => obj.id === cat_id
  //         );
  //         const coupons_ = [...allCouponsContext.state];
  //         coupons_.splice(couponIndex, 1);
  //         allCouponsContext.setState(coupons_);
  //       }
  //     })
  //     .catch((ex) => {
  //       setMessage({
  //         text: "Some error occured. Please try again !!",
  //         type: "error",
  //       });
  //       console.log(ex);
  //     });
  // };

  /// Add Coupon to firebase
  const handleSetCoupon = (
    action = "ADD",
    data = couponContext.state,
    payload = null
  ) => {
    console.log("data:", data);
    let coupon_id = "";
    if (payload !== null) {
      coupon_id = data;
    } else {
      coupon_id = data.id;
    }
    // API to add coupon data to firestore
    const res = couponAPI.setCoupon({
      id: coupon_id,
      payload: payload !== null ? payload : data,
    });
    res
      .then((resData) => {
        if (resData.success) {
          let action_msg = action === "ADD" ? "added" : "updated";
          setMessage({
            text: "Coupon " + action_msg + " successfully !",
            type: "success",
          });
          showSnackbar();
          if (action === "ADD") {
            couponContext.setState({
              ...data,
              id: coupon_id,
              createdAt: {seconds: Date.now() / 1000},
            });

            allCouponsContext.setState([
              {
                ...data,
                id: coupon_id,
                createdAt: {seconds: Date.now() / 1000},
              },
              ...allCouponsContext.state,
            ]);
          } else {
            const couponIndex = allCouponsContext.state.findIndex(
              (obj) => obj.id === coupon_id
            );
            const coupons_ = [...allCouponsContext.state];
            if (payload !== null) {
              coupons_[couponIndex] = {
                ...coupons_[couponIndex],
                ...payload,
              };
            } else {
              coupons_[couponIndex] = {
                ...coupons_[couponIndex],
                ...data,
              };
            }

            allCouponsContext.setState(coupons_);
          }
          couponContext.setState(initState);
          hideAddCouponModal();
        } else {
          setMessage({
            text: "Some error occured. Please try again !!",
            type: "error",
          });
          console.log(resData.message);
        }
      })
      .catch((ex) => {
        setMessage({
          text: "Some error occured. Please try again !!",
          type: "error",
        });
        console.log(ex);
      });
  };

  //// Columns for Coupons DataGrid
  const columns = [
    {field: "couponCode", headerName: "Code", width: 100},
    {field: "amount", headerName: "Discount Rs.", width: 120},
    {field: "minShopping", headerName: "on Min. Shopping", width: 180},
    {field: "maxUses", headerName: "Max. uses", width: 150},
    {
      field: "users",
      headerName: "Total Redeemed",
      width: 200,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.users.length}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 200,
      renderCell: (params) => {
        const t = new Date(
          params.row.createdAt.seconds * 1000
        ).toLocaleString();
        return <div className="rowitem">{t}</div>;
      },
    },
    {field: "id", hide: true},
    {
      field: "isActive",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            {params.row.isActive ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <DataGridActions
          {...{params}}
          onEdit={setEditCoupon}
          // onDelete={handleDeleteCoupon}
          onVisibilityChange={handleSetCoupon}
          itemName="Coupon"
        />
      ),
    },
  ];

  return (
    <>
      <Box component="div" sx={{display: {xs: "none", sm: "block"}}}>
        <Typography
          sx={{
            marginBottom: "2rem",
          }}
          variant="h4"
          component="h4"
        >
          Coupons
        </Typography>
      </Box>
      <Grid2 container spacing={2}>
        <Grid2>
          <CustomButton
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => showAddCouponModal("ADD")}
          >
            Add Coupon
          </CustomButton>
        </Grid2>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openCouponModal}
          onClose={hideAddCouponModal}
          closeAfterTransition
        >
          <Fade in={openCouponModal}>
            <Box sx={styles.boxStyle}>
              <AddCoupon
                action={editCouponModal ? "EDIT" : "ADD"}
                hideModal={hideAddCouponModal}
                handleSetCoupon={handleSetCoupon}
              />
            </Box>
          </Fade>
        </Modal>

        <Snackbar
          open={snackbarState}
          autoHideDuration={3000}
          onClose={hideSnackbar}
        >
          <Alert
            onClose={hideSnackbar}
            severity={message.type}
            sx={{width: "100%"}}
          >
            {message.text}
          </Alert>
        </Snackbar>

        <Grid2 xs={12} mt={2}>
          <CustomDataGrid
            rows={allCouponsContext.state}
            columns={columns}
            styles={{height: "540px"}}
            pageSizes={[10, 25, 50]}
          />
        </Grid2>
      </Grid2>
    </>
  );
};

export default CouponsListAdmin;
