import { Button, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import { useNavigate } from "react-router-dom";
const CustomMenu = ({
  mobile = false,
  items,
  uniqueKey,
  depthLevel,
  onHandleToggle,
}) => {
  // console.log("onCLose:", onClose);
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 599 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 599 && setDropdown(false);
  };

  return (
    <ul className="menu-ul" key={uniqueKey}>
      <li
        key={uniqueKey}
        className={`menu-items${mobile ? "-mobile" : ""}`}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {items.submenu.length > 0 ? (
          <>
            <Button
              className={depthLevel > 0 ? `action-link-submenu` : `action-link`}
              onClick={() => {
                setDropdown((prev) => !prev);
                !mobile && navigate(items.link);
              }}
              endIcon={
                depthLevel >= 0 && mobile ? (
                  dropdown ? (
                    <ArrowUpwardSharpIcon
                      fontSize="small"
                      sx={{ position: "absolute", right: "10px", top: "10px" }}
                    />
                  ) : (
                    <ArrowDownwardSharpIcon
                      sx={{ position: "absolute", right: "10px", top: "10px" }}
                    />
                  )
                ) : (
                  <></>
                )
              }
            >
              <Typography variant="body1">{items.title}</Typography>
            </Button>
            <Dropdown
              mobile={mobile}
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
              onHandleToggle={onHandleToggle}
            />
          </>
        ) : (
          <Button
            fullWidth={true}
            className={depthLevel > 0 ? `action-link-submenu` : `action-link`}
            onClick={() => {
              setDropdown((prev) => !prev);
              mobile && onHandleToggle();
              navigate(items.link);
            }}
          >
            <Typography variant="body1">{items.title}</Typography>
          </Button>
        )}
      </li>
    </ul>
  );
};

export default CustomMenu;
