import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import UserProfileContext from "../../context/userProfile/UserProfileContext";

export default function PrivateRoute({accessTo = "", Component, ...rest}) {
  const userProfileContext = useContext(UserProfileContext);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const currentUser = userProfileContext.state;
  useEffect(() => {
    //console.log("CHECK PRIVATE:", currentUser);
    if (currentUser === null) {
      //console.log("Sending to login page");
      return navigate("/login");
    }
    if (!currentUser.role.includes(accessTo)) {
      // Not authorized to access this route
      //console.log("No access for this user");
    }
    setLoading(false);
  }, [currentUser]);
  return !isLoading && <Component {...rest} />;
}
