import {Box, Divider, Grid, IconButton, Paper, Typography} from "@mui/material";
import React, {useRef, useContext, useState, useEffect} from "react";
import AllProductContext from "../../../../context/allProducts/AllProductsContext";
import AllCategoriesContext from "../../../../context/allCategories/AllCategoriesContext";
import CategoryBox from "../../../app/Categories/CategoryBox";
import ProductsContainer from "../../../app/Products/ProductsContainer";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
/**
 * Hook that alerts clicks outside of the passed ref
 */

const SearchResultsBox = ({styles, searchText, cancelSearchHandle}) => {
  const allProductsContext = useContext(AllProductContext);
  const allCategoriesContext = useContext(AllCategoriesContext);
  const [allCategories, setAllCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Console Log if clicked on outside of element
       */
      function handleClickOutside(event) {
        // console.log("ref:", ref.current);
        // console.log("event:", event.target.value, " searchText:", searchText);
        if (
          ref.current &&
          event.target.value !== searchText &&
          !ref.current.contains(event.target)
        ) {
          // console.log("You clicked outside of me!");
          cancelSearchHandle();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, searchText]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    if (allProductsContext.state.length > 0) {
      setAllProducts(allProductsContext.state);
    }
  }, [allProductsContext.state]);
  //useEffect for allCategoriesContext.state
  useEffect(() => {
    if (allCategoriesContext.state.length > 0) {
      setAllCategories(allCategoriesContext.state);
    }
  }, [allCategoriesContext.state]);

  // filter allProducts by title
  const filteredProducts = allProducts.filter((product) => {
    return product.title.toLowerCase().includes(searchText.toLowerCase());
  });
  // filter allCategories by name
  const filteredCategories = allCategories.filter((category) =>
    category.name.toLowerCase().includes(searchText.toLowerCase())
  );

  //console log filteredProducts
  // console.log("filteredProducts:", filteredProducts);
  // console.log("filteredCategories:", filteredCategories);
  return (
    <Paper sx={styles} ref={wrapperRef}>
      <IconButton
        sx={{position: "absolute", right: 0}}
        onClick={() => {
          cancelSearchHandle();
        }}
      >
        {/* Cross Icon */}
        <CloseOutlinedIcon
          sx={{
            fontSize: "24px",
            cursor: "pointer",
          }}
        />
      </IconButton>
      {/* Show Filtered Products */}
      {filteredProducts.length > 0 && (
        <Box
          sx={{
            height: "40vh",
            margin: "0.5rem 0",
            marginBottom: "0",
            padding: "0.5rem",
          }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              padding: "0 1rem 0 0",
              maxHeight: "100%",
              margin: "0 !important",
            }}
          >
            <Grid item xs={12} sx={{padding: "0 1rem !important"}}>
              <Typography variant="h6" sx={{fontWeight: "300", color: "grey"}}>
                Products
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{paddingTop: "0 !important", paddingLeft: "0 !important"}}
            >
              <ProductsContainer
                heading=""
                showInRow={true}
                addToCartEnable={false}
                showCarousel={false}
                productsArray={filteredProducts}
                useMobileSize={true}
                showLikeButton={false}
                cancelSearchHandle={cancelSearchHandle}
                showRating={true}
                showRatingNumber={false}
              />
            </Grid>

            {/* {filteredProducts.map((product) => {
              return (
                <Grid
                  item
                  xs={4}
                  sm={3}
                  key={product.id}
                  onClick={() => {
                    console.log("clicked");
                    cancelSearchHandle();
                  }}
                >
                  <ProductCard
                    key={product.id}
                    link={`/collections/${product.parentCategory.id}/${product.id}`}
                    product={product}
                    addToCartButton={false}
                    showCarousel={false}
                    fadeOutOnRemovingLike={false}
                    showLikeButton={false}
                  />
                </Grid>
              );
            })} */}
          </Grid>
        </Box>
      )}
      {/* Show Filtered Categories */}
      {filteredCategories.length > 0 && (
        <Box sx={{height: "42vh", margin: "0 0 1rem 0"}}>
          <Divider />
          <Typography
            variant="h6"
            sx={{padding: "1rem", fontWeight: "300", color: "grey"}}
          >
            Categories
          </Typography>
          <Box
            className="category-list"
            sx={{maxHeight: "85%", overflow: "auto", marginTop: 0}}
          >
            {filteredCategories.map((category) => {
              return (
                <Box
                  key={category.id}
                  onClick={() => {
                    console.log("clicked cat");
                    cancelSearchHandle();
                  }}
                  sx={{display: "contents"}}
                >
                  <CategoryBox
                    view="CIRCULAR"
                    title={category.name}
                    imgSrc={
                      category.images[0] !== undefined
                        ? category.images[0].url
                        : ""
                    }
                    link={`/collections/` + category.id}
                    inSearchResult={true}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      {/* No Items Found */}
      {filteredProducts.length === 0 && filteredCategories.length === 0 && (
        <Box>
          <Typography
            variant="h6"
            sx={{padding: "0.5rem 1rem", fontWeight: "300", color: "grey"}}
          >
            No Items Found
          </Typography>
          <Divider />
        </Box>
      )}
    </Paper>
  );
};

export default SearchResultsBox;
