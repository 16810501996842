import React, {useContext, useEffect, useState} from "react";
import Cart from "../../../components/app/Cart/Cart";
import CartItemsContext from "../../../context/cartItems/CartItemsContext";
import RecentlyViewedContext from "../../../context/recentlyViewed/RecentlyViewedContext";
import {Divider, Grid, Typography} from "@mui/material";
import ProductsContainer from "../../../components/app/Products/ProductsContainer";
import {styled} from "@mui/material/styles";
import cartEmptyGIF from "./../../../assets/gifs/cart-empty.gif";
import CustomButton from "../../../components/common/Button/CustomButton";
import {useNavigate} from "react-router-dom";
import AllProductsContext from "../../../context/allProducts/AllProductsContext";
import Loader from "../../../components/loader/Loader";
const Root = styled("div")({
  flexGrow: 1,
  padding: "1rem",
  marginTop: "0.75rem",
});
const CartPage = () => {
  const cartItemsContext = useContext(CartItemsContext);
  const [cartItemsArray, setCartItemsArray] = useState([]);
  const recentlyViewedContext = useContext(RecentlyViewedContext);
  const recentlyViewedItemsArray = recentlyViewedContext.state;
  const allProductsContext = useContext(AllProductsContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("cartItemsContext.state:", cartItemsContext.state);
    if (
      cartItemsContext.state !== null &&
      cartItemsContext.state.length !== 0
    ) {
      setCartItemsArray([...cartItemsContext.state]);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [cartItemsContext.state]);

  useEffect(() => {
    //console.log("cartItemsArray:", cartItemsArray);

    if (cartItemsArray.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [cartItemsArray]);

  return (
    <>
      {loading && <Loader type="LINEAR" fullHeight={false}></Loader>}
      {!loading && (
        <Root className="">
          {(cartItemsArray === null || cartItemsArray.length === 0) && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10} sm={6} md={4} lg={3}>
                <img width="100%" src={cartEmptyGIF} alt="loading..." />
                <Typography
                  sx={{textAlign: "center", marginTop: "1rem"}}
                  variant="h6"
                >
                  Your cart is empty
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomButton
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Continue Shopping
                </CustomButton>
              </Grid>
            </Grid>
          )}
          {cartItemsArray !== null && cartItemsArray.length > 0 && (
            <Cart products={cartItemsArray} />
          )}
          {/* Recently Viewed Items */}
          {recentlyViewedItemsArray.length > 0 && (
            <Grid container item spacing={2} sx={{marginTop: "1rem"}}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ProductsContainer
                  heading="Recently Viewed"
                  showInRow={true}
                  addToCartEnable={true}
                  showCarousel={false}
                  productsArray={recentlyViewedItemsArray}
                  showRatingNumber={false}
                />
              </Grid>
            </Grid>
          )}
          <Grid container item spacing={2} sx={{marginTop: "1rem"}}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ProductsContainer
                heading="Consider these products"
                showInRow={true}
                addToCartEnable={true}
                showCarousel={false}
                productsArray={allProductsContext.state}
                showRatingNumber={false}
              />
            </Grid>
          </Grid>
        </Root>
      )}
    </>
  );
};

export default CartPage;
