import React, { useState, useMemo } from "react";
import ColorModeContext from "./ColorModeContext";
import DashboardThemeLight from "../../themes/dashboard-light";
import DashboardThemeDark from "../../themes/dashboard-dark";
import { ThemeProvider } from "@mui/material/styles";

const ColorModeState = (props) => {
  const [state, setState] = useState("light");
  const value = useMemo(
    () => ({
      toggleColorMode: () => {
        setState((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  // useEffect(() => {
  //   console.log("color mode:", state);
  // }, [state]);
  return (
    <ColorModeContext.Provider value={value}>
      <ThemeProvider
        theme={state === "light" ? DashboardThemeLight : DashboardThemeDark}
      >
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ColorModeState;
