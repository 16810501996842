import React, {useState, useMemo, useEffect, useContext} from "react";
import CartItemsContext from "./CartItemsContext";
import UserDataAPI from "../../api/firebase/UserDataAPI";
import UserProfileContext from "../userProfile/UserProfileContext";
const CartItemsState = (props) => {
  const [state, setState] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const userDataApi = new UserDataAPI();
  const userProfileContext = useContext(UserProfileContext);
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cartItems"));
    // console.log("UseEffect===========localstorage:", items);
    if (items) {
      setState([...items]);
    }
  }, []);
  useEffect(() => {
    // console.log("cart items state:", state);
    if (!isFirstRender) {
      localStorage.setItem("cartItems", JSON.stringify(state));
      if (userProfileContext.state !== null && state !== null) {
        const dataToSend = {
          payload: {
            cartItems: state,
          },
          id: userProfileContext.state.id,
        };
        // console.log("data to send:", dataToSend);
        userDataApi
          .addUserData(dataToSend)
          .then((res) => {
            //console.log("RES:", res);
            if (res.success) {
              userProfileContext.setState((prev) => ({
                ...prev,
                ...dataToSend.payload,
              }));
            }
          })
          .catch((err) => {
            console.log("ERR:", err);
          });
      }
      // console.log(
      //   "localstorage cartItems:",
      //   JSON.parse(localStorage.getItem("cartItems"))
      // );
    }
    setIsFirstRender(false);
    return;
  }, [state]);
  return (
    <CartItemsContext.Provider value={value}>
      {props.children}
    </CartItemsContext.Provider>
  );
};

export default CartItemsState;
