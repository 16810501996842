import React, {useState, useMemo, useEffect} from "react";
import UserProfileContext from "./UserProfileContext";
import AuthAPI from "../../api/firebase/AuthAPI";

const UserProfileState = (props) => {
  const [state, setState] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const authAPI = new AuthAPI();

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem("user"));
    // console.log(
    //   "UseEffect in UserProfileState===========>localStorage User:",
    //   userProfile
    // );
    if (userProfile) {
      const userId = userProfile.id || userProfile.uid;
      // console.log(
      //   "In UserProfileState ======> Fetch detail by user id:",
      //   userId
      // );
      // Fetch User Data using userID from firestore database
      authAPI.getUserDetailById(userId).then((res) => {
        // console.log("In UserProfileState ======> RES here:", res);
        setState({...res, id: userId});
      });
    }
  }, []);
  useEffect(() => {
    //console.log("UserProfileState state:", state);
    if (!isFirstRender) {
      localStorage.setItem("user", JSON.stringify(state));
      // console.log(
      //   "localstorage user:",
      //   JSON.parse(localStorage.getItem("user"))
      // );
    }
    return setIsFirstRender(false);
  }, [state]);

  return (
    <UserProfileContext.Provider value={value}>
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileState;
