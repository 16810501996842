import React, {useState, useMemo} from "react";
import AddCouponContext from "./AddCouponContext";

const AddCouponState = (props) => {
  const initState = {
    id: "",
    couponCode: "",
    amount: "",
    isActive: true,
    maxUses: "",
    minShopping: "",
    users: [],
  };
  const [state, setState] = useState(initState);
  // useEffect(() => {
  //   console.log("Add Coupon state:", state);
  // }, [state]);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  return (
    <AddCouponContext.Provider value={value}>
      {props.children}
    </AddCouponContext.Provider>
  );
};

export default AddCouponState;
