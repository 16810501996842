import {Container, Typography} from "@mui/material";
import React from "react";

const AboutPage = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mt="2rem">
        About {process.env.REACT_APP_NAME}
      </Typography>
    </Container>
  );
};

export default AboutPage;
